
<script>
import { Bar } from 'vue-chartjs'

export default {
    name:'BarChart',
    extends: Bar,

    props: {
        data: {
            type: Object, 
            required: true,
            default() {return {}}
        }

        // Sample data object
        // chartData: {
        //     id: 'test2',
        //     content: 'Lorem ipsum dolor sit amet',
        //     datasets: {
        //         labels: ["2015-01", "2015-02", "2015-03", "2015-04", "2015-05"],
        //         datasets: [{
        //                 label: 'orange',
        //                 borderWidth: 0,
        //                 barPercentage: 0.5,
        //                 backgroundColor: '#f29100ff',
        //                 data: [12, 19, 3, 5, 2]
        //             }, 
        //             {
        //                 label: 'blue',
        //                 borderWidth: 0,
        //                 barPercentage: 0.5,
        //                 backgroundColor: '#b5e8fcff',
        //                 data: [12, 1, 13, 25, 32]
        //             }
        //         ]
        //     }
        // },
    },
    
    data() {
        return {
            chartData: this.data.datasets,
            options: {}
        }
    },

    methods: {
        setOptions() {
            this.options = {
                normalized: true,
                parsing: false,
                animation: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontColor: 'black',
                            // max: 5,
                            min: 0,
                            // stepSize: 1,
                            maxTicksLimit: 10,
                            defaultFontStyle: 'bold'
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    xAxes: [{
                        barPercentage: 0.9,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            fontColor: 'black',
                            autoSkip: true,
                            maxTicksLimit: 20,
                            // sampleSize: 100
                        },
                    }]
                },
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: false,
                    position: 'bottom',
                }
            }
        }
    },

    mounted() {
        this.setOptions()
        this.renderChart(this.chartData, this.options)
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.chartData = value.datasets
                this.setOptions()
            }
        },
    },
}
</script>