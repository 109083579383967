const THEMES = ['orange', 'blue'];

export default {
    name:"ImageButton",
    props: {
        data: {
            type: Object,
            required: true,
            default() {return {}}
        },
        theme: {
            type: String,
            default: THEMES[0],
            validator: (val) => THEMES.includes(val)
        },
    },

    data() {
        return {
            content: {},
            chosenTheme: this.theme
        }
    },

    methods: {
        setTheme(theme) {
            const isThemeValid = THEMES.includes(theme);
            isThemeValid? this.chosenTheme = theme : this.chosenTheme = THEMES[0]
        },
        handleClick(id) {
            this.$emit("click", id)
        },
        handleImageClick(id) {
            this.$emit("image-click", id)
        }
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.content = value
            }
        },
        theme: {
            immediate: true,
            handler(value) {
                this.setTheme(value)
            }
        }
    }
}