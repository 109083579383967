<template>
    <div id="app" style="width: 100%; height: 100%;">
        <wordcloud
            style="width: 100%; height: 100%;"
            :data="wordsObject.words"
            nameKey="name"
            valueKey="value"
            :color="wordsObject.colors"
            :showTooltip="true">
        </wordcloud>
    </div>
</template>

<script>
import wordcloud from 'vue-wordcloud'

export default {
    name: "WordCloud",
    components: {
        wordcloud
    },

    props: {
        data: {
            type: Object,
            default() {return {}},
            required: true
        }

        // Sample data object
        // wordCloudData: {
        //     id: "word-cloud",
        //     content: 'Lorem ipsum dolor sit amet',
        //     colors: ['#1f77b4', '#629fc9', '#94bedb', '#c9e0ef'],
        //     words: [
        //         {
        //             "name": "Cat",
        //             "value": 26
        //         },
        //         {
        //             "name": "fish",
        //             "value": 19
        //         },
        //         {
        //             "name": "things",
        //             "value": 18
        //         },
        //         {
        //             "name": "look",
        //             "value": 16
        //         }
        //     ]
        // }
    },

    data() {
        return {
            wordsObject: this.data,
        }
    },

    // methods: {
    //     wordClickHandler(name, value, vm) {
    //         // console.log('wordClickHandler', name, value, vm);
    //     }
    // },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler() {
                this.wordsObject = this.data
            }
        }
    }
}
</script>

<style lang="scss" scoped>
div.tooltip {
    font-size: 10px;
    padding: 20px;
}
</style>