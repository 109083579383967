
<script>
import { Bar } from 'vue-chartjs'

export default {
    name:'StackedBarChart',
    extends: Bar,

    props: {
        data: {
            type: Object, 
            required: true,
            default() {return {}}
        }

        // Sample data object
        // chartData: {
        //     id: 'test2',
        //     content: 'Lorem ipsum dolor sit amet',
        //     datasets: {
        //         labels: ["2015-01", "2015-02", "2015-03", "2015-04", "2015-05"],
        //         datasets: [{
        //                 label: 'blue',
        //                 borderWidth: 0,
        //                 barPercentage: 0.5,
        //                 backgroundColor: '#b5e8fcff',
        //                 data: [12, 19, 3, 5, 2]
        //             }, 
        //             {
        //                 label: 'gray',
        //                 borderWidth: 0,
        //                 barPercentage: 0.5,
        //                 backgroundColor: '#bcbcbcff',
        //                 data: [12, 1, 13, 25, 32]
        //             }, {
        //                 label: 'orange',
        //                 borderWidth: 0,
        //                 barPercentage: 0.5,
        //                 backgroundColor: '#f29100ff',
        //                 data: [2, 9, 13, 15, 12]
        //         }]
        //     }
        // }
    },
    
    data() {
        return {
            chartData: this.data.datasets,
            options: {}
        }
    },

    methods: {
        setOptions(id) {
            this.options = {
                scales: {
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            fontColor: 'black',
                            max: 100,
                            min: 0,
                            stepSize: 20,
                            defaultFontStyle: 'bold'
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            fontColor: 'black'
                        },
                    }]
                },
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: false
                },
                legendCallback: function(chart) {
                    var legend = document.getElementById("chart-legend-"+ id)
                    var datasets = chart.data.datasets

                    legend.innerHTML = '';
     
                    var ul = document.createElement('ul');
                    ul.classList.add("legend-list", "d-flex", "justify-content-around");
                    legend.appendChild(ul)

                    for (let i = 0; i < datasets.length; i++) {
                        var dataset = datasets[i]
                        var color = dataset.backgroundColor;

                        ul.innerHTML += 
                            '<li class="d-flex">' +
                                '<span class="align-self-center" style="font-size: 12px;">' + dataset.label + '</span>' +
                                '<div class="align-self-center mx-1" style="width: 18px; height: 18px; border-radius: 3px; background-color:' + color + '"></div>' +
                            '</li>'
                        ;

                        if (i < datasets.length -1) {
                           ul.innerHTML +=  '<span class="mx-2" style="color: #aaa;"> | </span>' ;
                        }
                    }
                }
            }
        }
    },

    mounted() {
        this.setOptions(this.data.id)
        this.renderChart(this.chartData, this.options)
        this.generateLegend()
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.chartData = value.datasets
                this.setOptions(value.id)
            }
        },
    },
}
</script>