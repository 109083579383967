
<script>
import { Pie } from 'vue-chartjs'

export default {
    name:'PieChart',
    extends: Pie,

    props: {
        data: {
            type: Object, 
            required: true,
            default() {return {}}
        },

        legendPlacement: {
            type: String,
            default: "left"
        }

        // Sample data object
        // chartData: {
        //     id: 'test1',
        //     content: 'Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem Lorem ipsum dolor sit amet ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
        //     datasets: {
        //         labels: ['Pie 1', 'Pie 2', 'Pie 3', 'Pie 4', 'Pie 5'],
        //         datasets: [
        //             {
        //                 backgroundColor: [ '#1E9600', '#99C802', '#FFF200', '#F89403',	'#FF0000' ],
        //                 data: [1, 2, 3, 4, 5],
        //             },
        //         ],
        //     }
        // },
    },

    data() {
        return {
            chartData: this.data.datasets,
            options: {}
        }
    },

    methods: {
        setOptions() {
            this.options = {
                responsive: true,
                animation: false,
                legend: {
                    display: true,
                    position: this.legendPlacement,
                },
                // plugins: {
                    // legend: {
                    //     display: true,
                    //     maxHeight: '10px',
                    //     position: "left",
                    //     labels: {
                    //         color: 'rgb(255, 99, 132)'
                    //     }
                    // }
                // }
            }
        }
    },

    mounted() {
        this.setOptions()
        this.renderChart(this.chartData, this.options)
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.chartData = value.datasets
                this.setOptions()
            }
        },
    },
}
</script>