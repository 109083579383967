import InformationCard from "@shared/InformationCard/InformationCard.vue";
import ImageButton from "@shared/ImageButton/ImageButton.vue";
import FocusedInformationCard from "@shared/FocusedInformationCard/FocusedInformationCard.vue";
import ChartDisplay from "@shared/ChartDisplay/ChartDisplay.vue";
import Tabs from "@shared/Tabs/Tabs.vue";
import SearchBar from "@shared/SearchBar/SearchBar.vue";
import SearchFilter from "@shared/Filter/Filter.vue";

export default {
  name: "TestZone",

  components: {
    InformationCard,
    FocusedInformationCard,
    ImageButton,
    ChartDisplay,
    Tabs,
    SearchBar,
    SearchFilter,
  },

  data() {
    return {
      info: {
        id: "test1",
        src: "images/test-image-1.jpeg",
        title: "لرم إبسوم لرم إبسوم",
        bodyTitle: "عندما يريد",
        date: "2015| 04 |12",
        language: "يتحّدث: بلغة يونيكود",
        description:
          " وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... عند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع الكثير.",
      },
      info2: {
        id: "test2",
        src: "images/test-image-2.jpg",
        title: "لرم إبسوم لرم إبسوم",
        bodyTitle: "عندما يريد",
        date: "2015| 04 |12",
        language: "يتحّدث: بلغة يونيكود",
        description:
          " وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... عند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع الكثير.",
      },
      info3: {
        id: "test3",
        src: "images/test-image-3.jpg",
        title: "لرم إبسوم لرم إبسوم",
        bodyTitle: "عندما يريد",
        date: "2015| 04 |12",
        language: "يتحّدث: بلغة يونيكود",
        description:
          " وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... عند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع الكثير.",
      },

      imageButtonData: {
        id: "photos",
        title: "الصور الفوتوغرافية",
        images: [
          "images/test-image-1.jpeg",
          "images/test-image-2.jpg",
          "images/test-image-3.jpg",
          "images/test-image-1.jpeg",
          "images/test-image-1.jpeg",
          "images/test-image-1.jpeg",
          "images/test-image-1.jpeg",
          "images/test-image-1.jpeg",
          "images/test-image-3.jpg",
          "images/test-image-1.jpeg",
        ],
      },

      imageButtonData2: {
        id: "articles",
        title: "التبویب الصحافية",
        images: [
          "images/test-image-3.jpg",
          "images/test-image-2.jpg",
          "images/test-image-1.jpeg",
          "images/test-image-1.jpeg",
          "images/test-image-2.jpg",
          "images/test-image-3.jpg",
          "images/test-image-1.jpeg",
          "images/test-image-2.jpg",
          "images/test-image-1.jpeg",
          "images/test-image-1.jpeg",
        ],
      },

      tabNames: ["chart 1", "chart 2"],

      chartData1: {
        id: "test1",
        content: "Lorem ipsum dolor sit amet",
        datasets: {
          labels: ["2015-01", "2015-02", "2015-03", "2015-04", "2015-05"],
          datasets: [
            {
              label: "blue",
              borderWidth: 0,
              barPercentage: 0.5,
              backgroundColor: "#b5e8fcff",
              data: [12, 19, 3, 5, 2],
            },
            {
              label: "gray",
              borderWidth: 0,
              barPercentage: 0.5,
              backgroundColor: "#bcbcbcff",
              data: [12, 1, 13, 25, 32],
            },
            {
              label: "orange",
              borderWidth: 0,
              barPercentage: 0.5,
              backgroundColor: "#f29100ff",
              data: [2, 9, 13, 15, 12],
            },
          ],
        },
      },

      chartData2: {
        id: "test2",
        content: "Lorem ipsum dolor sit amet",
        datasets: {
          labels: ["2015-01", "2015-02", "2015-03", "2015-04", "2015-05"],
          datasets: [
            {
              label: "blue",
              borderWidth: 0,
              barPercentage: 0.5,
              backgroundColor: "#b5e8fcff",
              data: [12, 19, 3, 5, 2],
            },
            {
              label: "gray",
              borderWidth: 0,
              barPercentage: 0.5,
              backgroundColor: "#bcbcbcff",
              data: [12, 1, 13, 25, 32],
            },
            {
              label: "orange",
              borderWidth: 0,
              barPercentage: 0.5,
              backgroundColor: "#f29100ff",
              data: [2, 9, 13, 15, 12],
            },
          ],
        },
      },
    };
  },
};
