import BarChart from '@components/frontend/BarChart';
import PieChart from '@components/frontend/PieChart';
import StackedBarChart from '@components/frontend/StackedBarChart';
import WordCloud from '@components/frontend/WordCloud';

const TYPES = ['bar', 'pie', 'word-cloud', 'stacked-bar'];

export default {
    name:"ChartDisplay",
    components: {
        BarChart,
        PieChart,
        StackedBarChart,
        WordCloud
    },

    props: {
        data: {
            type: Object,
            required: true,
            default() {return {}}
        },
        chartType: {
            type: String,
            default: TYPES[0],
            validator: (val) => TYPES.includes(val)
        },
    },

    data () {
        return {
            chartKey: 0,
            chartData: this.data,

            pieChartWidth: 300,
            pieChartHeight: 270,
            pieChartKey: 0,
            pieChartLegendPlacement: "bottom",

            barChartWidth: 300,
            barChartHeight: 270,
            barChartKey: 0
        }
    },

    created() {
        this.setChartSize(window.innerWidth)
        window.addEventListener("resize", this.myEventHandler);
    },

    methods: {
        myEventHandler() {
            if (window.innerWidth) {
                this.setChartSize(window.innerWidth)
            }
            this.chartKey++
            this.pieChartKey++
            this.barChartKey++
        },

        setChartSize(windowWidth) {
            if (windowWidth >= 991 ) {
                this.pieChartLegendPlacement = "left"

                this.pieChartWidth = 900
                this.pieChartHeight = 300

                this.barChartWidth = 900
                this.barChartHeight = 300

            }  else if (windowWidth >= 768) {
                this.pieChartLegendPlacement = "left"

                this.pieChartWidth = 700
                this.pieChartHeight = 300

                this.barChartWidth = 700
                this.barChartHeight = 300
            }  else if (windowWidth >= 576) {
                this.pieChartLegendPlacement = "bottom"

                this.pieChartWidth = 500
                this.pieChartHeight = 400

                this.barChartWidth = 500
                this.barChartHeight = 270
            } else {
                this.pieChartLegendPlacement = "bottom"

                this.barChartWidth = 300
                this.barChartHeight = 270

                this.pieChartWidth = 300
                this.pieChartHeight = 270
            }
        }
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.chartData = value
            }
        },
    },

    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
}